// Import React
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React from 'react';
import { Navbar, PageFooter } from '../components';
import PostCard from '../components/PostCard';
// import our styles
import '../style/style.scss';

export const query = graphql`
  query SearchQuery {
    allPrismicBenefit {
      edges {
        node {
          uid
          data {
            body {
              ... on PrismicBenefitBodyBenefitBodyText {
                id
                primary {
                  benefit_body_text_content {
                    text
                  }
                }
                slice_label
                slice_type
              }
            }

            benefit_name
            benefit_type
            benefit_provider {
              url
              document {
                ... on PrismicProviders {
                  id
                  data {
                    provider_name
                    provider_bg {
                      url
                      dimensions {
                        height
                        width
                      }
                      alt
                    }
                    provider_logo {
                      url
                      dimensions {
                        height
                        width
                      }
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicPost(sort: { fields: data___post_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            post_date
            post_title
            post_seo_title
            post_seo_description
            post_seo_robots
            post_append_site_title
            body {
              ... on PrismicPostBodyTextBlock {
                id
                slice_label
                slice_type
                primary {
                  post_text_content {
                    raw
                  }
                }
              }
              ... on PrismicPostBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  post_image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Search = ({ data, pageContext }) => {
  const { settings } = pageContext,
    queryParams = new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    );
  let searchString = queryParams.get('searchString');
  if (searchString) {
    searchString = searchString.toLowerCase();
  }
  // const edges = !searchString
  //   ? []
  //   : data.allPrismicPost.edges.filter(
  //       (edge) => (edge.node.data.post_title || '').indexOf(searchString) !== -1
  //     );
  const edges = !searchString
    ? []
    : data.allPrismicPost.edges.filter((edge) => {
        const postTitle = edge.node.data.post_title.toLowerCase() || '';
        const postTextContent = edge.node.data.body
          .filter((slice) => slice.slice_type === 'text_block')
          .map((slice) =>
            slice.primary.post_text_content.raw[0].text.toLowerCase()
          )
          .join(' ');

        return (
          postTitle.indexOf(searchString) !== -1 ||
          postTextContent.indexOf(searchString) !== -1
        );
      });

  // console.log({edges})
  //const benefitsEdges =data.allPrismicBenefit.edges;
  const benefitsEdges = !searchString
    ? []
    : data.allPrismicBenefit.edges.filter((edge) => {
        const primary = edge.node.data.body[0]?.primary;
        const benefitName = edge.node.data.benefit_name.toLowerCase() || '';
        const benefitType = edge.node.data.benefit_type.toLowerCase() || '';
        const providerName =
          edge.node.data.benefit_provider.document.data.provider_name.toLowerCase() ||
          '';
        const benefitTextContent =
          primary?.benefit_body_text_content.text.toLowerCase() || '';

        // console.log({providerName, searchString})
        return (
          (primary && benefitTextContent.indexOf(searchString) !== -1) ||
          (benefitName && benefitName.indexOf(searchString) !== -1) ||
          (benefitType && benefitType.indexOf(searchString) !== -1) ||
          (providerName && providerName.indexOf(searchString) !== -1)
        );
      });
  // console.log({benefit: benefitsEdges[0].node.data.body[0].primary.benefit_body_text_content.text})
  // console.log({benefitsEdges},{allPrismicBenefit: data.allPrismicBenefit})

  return (
    <main className="page has-parent">
      <Navbar {...settings.data} />
      <div className="container post-container">
        <h1 className="text-center">{'Search'}</h1>
        {!edges.length && !benefitsEdges.length && (
          <div className="text-center">
            <p>No results found.</p>
          </div>
        )}

        <div className="container">
          <div className="row">
            {edges.length > 0 && (
              <>
                <div>News</div>
                {edges.map(({ node }) => {
                  // get all the images from the post slice_type image_block
                  const postTextContent = node.data.body
                    .filter((slice) => slice.slice_type === 'text_block')
                    .map((slice) => slice.primary.post_text_content.raw[0].text)
                    .join(' ');
                  const images = node.data.body.filter(
                    (slice) => slice.slice_type === 'image_block'
                  );
                  // map the images to an array of urls
                  const imageUrls = images.map(
                    (image) => image.primary.post_image.url
                  );
                  return (
                    <div className="col-12 col-lg-6 p-4" key={node.uid}>
                      <PostCard
                        title={node.data.post_title}
                        date={node.data.post_date}
                        description={postTextContent}
                        image={imageUrls ? imageUrls[0] : null}
                        link={`/news/${node.uid}`}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="row">
            {benefitsEdges.length > 0 && (
              <>
                <div>Benefits</div>
                {benefitsEdges.map(({ node }) => {
                  const date =
                    node.data.benefit_provider.document.last_publication_date;
                  const description =
                    node.data.body[0].primary?.benefit_body_text_content
                      ?.text || '';
                  // get all the images from the post slice_type image_block
                  const image =
                    node.data.benefit_provider.document.data.provider_bg.url;

                  // map the images to an array of urls
                  const imageUrls = [image];
                  const benefit_type = node.data.benefit_type;
                  const providerName =
                    node.data.benefit_provider.document.data.provider_name;
                  return (
                    <div className="col-12 col-lg-6 p-4" key={node.uid}>
                      <PostCard
                        title={
                          node.data.benefit_name +
                          ' - ' +
                          providerName +
                          ' | ' +
                          benefit_type.charAt(0).toUpperCase() +
                          benefit_type.slice(1) +
                          ' Benefits'
                        }
                        date={date}
                        description={description}
                        image={imageUrls ? imageUrls[0] : null}
                        link={`/benefits/${benefit_type}/${providerName.toLowerCase()}/${
                          node.uid
                        }`}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {/* <div className=" text-center">
              {previousButton} {nextButton}
            </div> */}
        </div>
      </div>
      <PageFooter />
    </main>
  );
};

export default withPreview(Search);
